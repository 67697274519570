import { useSoyuzExperiment } from '@spotahome/soyuz/client';

import { useCookie } from '../tenant';

const COOKIE_NAME_PREFIX = 'experiment__';

const useExperimentCookie = experimentName => {
  const cookieManager = useCookie();
  const experiment = useSoyuzExperiment(experimentName);
  const cookieKey = `${COOKIE_NAME_PREFIX}${experimentName}`;

  const assignExperimentCookie = () => {
    const experimentBehaviour = experiment.get('behaviour');
    cookieManager.setCookie(cookieKey, experimentBehaviour, { path: '/' });
    return experimentBehaviour;
  };

  const getExperimentAssignationFromCookie = () => {
    return cookieManager.getCookie(cookieKey) ?? 'original';
  };

  return {
    assignExperimentCookie,
    getExperimentAssignationFromCookie
  };
};

export default useExperimentCookie;
