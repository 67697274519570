/* eslint-disable import/no-named-as-default-member */
import { Fragment, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { getActiveBanners } from '@spotahome/marketplace-common/src/components/HomeCardBanner/config';
import HomeCardBanner from '@spotahome/marketplace-common/src/components/HomeCardBanner';

import Homecard from '@spotahome/marketplace-common/src/components/Homecard';

import IconLoading from '@spotahome/marketplace-common/src/LEGACY/javascripts/ui/icon/icon-loading';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';
import { HomecardFormatter } from '@spotahome/ui-library/src/tenant/utils/images';

import { getMonthlyPrice } from '@spotahome/marketplace-common/src/utils/homecards';

import { useSoyuzLocales } from '@spotahome/soyuz/client';

import { getCardsFromIds } from '../../utils/getCardsFromIds';

import { useHighlightMarkersContext } from '../../context/HighlightMarkersContext';

import AlertsCreationReminder from '../AlertsCreationReminder/AlertsCreationReminder';

import ValueProposition from '../ValueProposition';

import useHomecardListTracking from './utils/homecardListTracking';

import EmptyList from './EmptyList';

import './HomeCardList.scss';

const hasNewMarkers = (oldMarkers, newMarkers) =>
  JSON.stringify(oldMarkers.map(e => e.id)) !==
  JSON.stringify(newMarkers.map(e => e.id));

const scrollPageToTop = () => {
  document.querySelector('html').scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
};

export const HomeCardList = ({
  markerList,
  cityId,
  deviceType,
  initialHomecards = {},
  hasLazyLoad = false,
  hidePriceWithoutDates = false,
  showAlertCreationReminder = false,
  preloadedHomecards = [],
  hideBanners = false
}) => {
  const { filters } = useFiltersContext();
  const prevMarkers = useRef(markerList);
  const homecardListRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [homecards, setHomecards] = useState(initialHomecards);
  const {
    trackInitialMount,
    trackHomecardClick,
    trackProductImpressions,
    triggerHotjarRecording
  } = useHomecardListTracking(cityId);
  const { handleCardMouseEnter, handleCardMouseLeave, markerClickedId } =
    useHighlightMarkersContext();
  const { current: currentLocale } = useSoyuzLocales();

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    trackInitialMount(markerList, homecards);
  }, []);

  useEffect(() => {
    const requestNewHomecards = async () => {
      setIsLoading(true);
      const ids = markerList.map(card => card.id);
      const newHomecards = await getCardsFromIds(ids, currentLocale);
      trackProductImpressions(markerList, newHomecards);
      triggerHotjarRecording(homecards);

      setHomecards(newHomecards);
      prevMarkers.current = markerList;

      setIsLoading(false);
    };

    if (hasNewMarkers(prevMarkers.current, markerList)) {
      scrollPageToTop();
      requestNewHomecards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerList]);

  const handleHomecardImageChange = (action, { index }) => {
    SoyuzAnalytics.sendGA4Event(`homecard-image-slide`, { action, index });
  };

  const renderHomecardList = () => {
    const shouldShowAvailableFrom = !filters['move-in'];

    const shouldShowPrice = !(hidePriceWithoutDates && !filters['move-in']);
    const [activeBanner] = getActiveBanners(cityId);

    const availableHomecards = markerList.filter(({ id }) => homecards[id]);

    return availableHomecards
      .map((homecard, position) => {
        const cardData = homecards[homecard.id];

        const data = {
          ...cardData,
          photos: HomecardFormatter.getPhotos(
            cardData.photoIds,
            cardData.otherPropertyPhotoIds
          ),
          photoUrls: HomecardFormatter.getPhotoUrls(cardData.photoIds),
          monthlyPrice: getMonthlyPrice(cardData.displayPrice)
        };

        const firstAvailableDate = new Date(data.firstAvailableDate);
        const firstAvailableYear = firstAvailableDate.getFullYear();

        return (
          <Fragment key={`homecard-${homecard.id}`}>
            {!hideBanners &&
            activeBanner &&
            activeBanner.position === position ? (
              <HomeCardBanner
                name={activeBanner.name}
                component={activeBanner.component}
                bannerConfig={activeBanner.componentConfig}
                bannerTracking={activeBanner.tracking}
                isOneColumnView={
                  homecardListRef.current.clientWidth &&
                  homecardListRef.current.firstChild.clientWidth ===
                    homecardListRef.current.clientWidth
                }
              />
            ) : null}

            <div className="l-list__item" data-homecard-scroll={homecard.id}>
              <Homecard
                isShowStatus
                data={data}
                position={position}
                moveIn={filters['move-in']}
                moveOut={filters['move-out']}
                flexibleMoveOut={!!filters.flexibleMoveOut}
                flexibleMoveIn={!!filters.flexibleMoveIn}
                onCardMouseEnter={handleCardMouseEnter}
                onCardMouseLeave={handleCardMouseLeave}
                onClick={() => {
                  trackHomecardClick(position, data);
                }}
                reviews={data.reviews}
                selected={homecard.id === markerClickedId}
                hasLazyLoad={
                  preloadedHomecards.includes(data.id) ? false : hasLazyLoad
                }
                showAvailableFrom={shouldShowAvailableFrom}
                showPrice={shouldShowPrice}
                newTab={
                  !(deviceType === 'smartphone' || deviceType === 'tablet')
                }
                showAvailableFromYear={firstAvailableYear !== currentYear}
                pageType="search"
                showDescription
                onImageChange={handleHomecardImageChange}
                isBooked={homecard.so}
              />
            </div>
          </Fragment>
        );
      })
      .filter(Boolean);
  };

  const hasHomecards = markerList && Object.keys(homecards).length;

  return (
    <div
      data-cy="property-list"
      data-test="property-list"
      className="l-list"
      ref={homecardListRef}
    >
      {isLoading ? <EmptyList /> : null}
      {!isLoading && hasHomecards ? renderHomecardList() : null}
      {!isLoading && showAlertCreationReminder ? (
        <AlertsCreationReminder cityId={cityId} />
      ) : null}
      <div className="spinner-loading-map" hidden={!isLoading}>
        <IconLoading />
      </div>
    </div>
  );
};

HomeCardList.propTypes = {
  markerList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialHomecards: PropTypes.shape({}),
  cityId: PropTypes.string.isRequired,
  hasLazyLoad: PropTypes.bool,
  hidePriceWithoutDates: PropTypes.bool,
  showAlertCreationReminder: PropTypes.bool,
  preloadedHomecards: PropTypes.arrayOf(PropTypes.string),
  deviceType: PropTypes.string.isRequired,
  hideBanners: PropTypes.bool
};

export default HomeCardList;
