import isEqual from 'lodash/isEqual';
import { formatPrice } from '@spotahome/soyuz-currency';
import { trans } from '@spotahome/soyuz/client';

import { isEmpty } from 'lodash';

import {
  getSortingName,
  isSortByPOI,
  getDefaultSortBy,
  DEFAULT_SORT_BY
} from './sorting';

import {
  filtersObjectFromUrl,
  shouldAvoidParamInTheUrl as paramHasDefaultValue
} from './search-url-generator';

export const EXCLUDED_FILTERS_FROM_COUNT = [
  'contractDurationTypesAllowed',
  'sortBy',
  'poi',
  'area',
  'seoMode',
  'page',
  'poiLat',
  'poiLng',
  'poiName',
  'poiId',
  'show-modal',
  'overrideExperiment',
  'overrideExperiment[]',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'map',
  'mapCenter'
];

export const DEFAULT_FILTERS = {
  contractDurationTypesAllowed: '',
  'move-in': '',
  'move-out': '',
  flexibleMoveIn: 1,
  flexibleMoveOut: 1,
  'features[]': [],
  'type[]': [],
  'topology[]': [],
  'bathrooms[]': [],
  bed: '',
  budget: '',
  poi: '',
  sortBy: DEFAULT_SORT_BY,
  noDeposit: 0,
  instantBooking: 0,
  plan: '',
  verified: null,
  isBestChoice: 0,
  'ids[]': [],
  'areaId[]': [],
  page: 1,
  poiLat: null,
  poiLng: null,
  'rentalType[]': [],
  moveOutFrom: '',
  moveOutTo: '',
  moveInFrom: '',
  moveInTo: ''
};

const DEFAULT_FILTERS_NAMES = Object.keys(DEFAULT_FILTERS);

export const getBudgetValue = ({ minBudget, maxBudget, maxPrice }) => {
  const maxBudgetInt = parseInt(maxBudget, 10);
  const minBudgetInt = parseInt(minBudget, 10);
  const formattedMax = maxBudget ? `-${maxBudgetInt}` : '';
  let budgetValue = `${minBudgetInt}${formattedMax}`;

  if (maxPrice && maxBudgetInt >= maxPrice) {
    budgetValue = minBudgetInt ? `${minBudgetInt}` : '';
  }
  return budgetValue;
};

export const DEFAULT_FILTERS_TRANSLATED = Object.entries(
  DEFAULT_FILTERS
).reduce(
  (acc, [name, value]) => ({
    ...acc,
    [name.replace('[]', '')]: value
  }),
  {}
);

const { sortBy: sortByExcluded, ...restDefaultFilters } =
  DEFAULT_FILTERS_TRANSLATED;
const { type, ...restFiltersNoPropertyType } = restDefaultFilters;

export const DEFAULT_FILTERS_TRANSLATED_NO_SORT = restDefaultFilters;
export const DEFAULT_FILTERS_TRANSLATED_PLUS_PROPERTY_TYPE =
  restFiltersNoPropertyType;

export const getActiveFilters = filters =>
  Object.entries(filters).filter(
    ([key, value]) =>
      !!value &&
      !EXCLUDED_FILTERS_FROM_COUNT.includes(key) &&
      !paramHasDefaultValue(key, value) &&
      (!Array.isArray(value) || value.length > 0)
  );
export const getActiveFiltersCount = (filters, disabledFilters = []) =>
  Object.entries(filters).filter(
    ([key, value]) =>
      !!value &&
      DEFAULT_FILTERS_NAMES.includes(key) &&
      !EXCLUDED_FILTERS_FROM_COUNT.includes(key) &&
      !paramHasDefaultValue(key, value) &&
      (!Array.isArray(value) || value.length > 0) &&
      !disabledFilters.includes(key)
  ).length;

export const getDefaultFilters = () => DEFAULT_FILTERS;

export const getInitialFilters = originalUrl => {
  return {
    ...DEFAULT_FILTERS,
    ...filtersObjectFromUrl(originalUrl)
  };
};

export const formatBudget = (budget, currencyIsoCode) => {
  const [minPrice, maxPrice] = budget.split('-');
  const minPriceFormatted = formatPrice(minPrice, currencyIsoCode);
  if (maxPrice) {
    return `${minPriceFormatted} - ${formatPrice(maxPrice, currencyIsoCode)}`;
  }
  return `${trans('filters.price.from')} ${minPriceFormatted}`;
};

const adaptCommonFilters = (filters, sortingAlgorithm) => {
  let sortBy = getSortingName(filters.sortBy, sortingAlgorithm);

  if (isSortByPOI(sortBy) && (!filters.poiLat || !filters.poiLng)) {
    sortBy = getDefaultSortBy();
  }

  return {
    bed: filters.bed,
    budget: filters.budget,
    contractDurationTypesAllowed: filters.contractDurationTypesAllowed,
    page: filters.page,
    noDeposit: Number(filters.noDeposit),
    instantBooking: filters.instantBooking,
    plan: filters.plan,
    poiLat: filters.poiLat ? parseFloat(filters.poiLat) : null,
    poiLng: filters.poiLng ? parseFloat(filters.poiLng) : null,
    verified: filters.verified || null,
    isBestChoice: filters.isBestChoice,
    sortBy
  };
};

export const adaptFiltersFromUrl = (filters, sortingAlgorithm) => ({
  ...adaptCommonFilters(filters, sortingAlgorithm),
  flexibleMoveIn: parseInt(filters.flexibleMoveIn, 10),
  flexibleMoveOut: parseInt(filters.flexibleMoveOut, 10),
  bathrooms: filters['bathrooms[]'],
  features: filters['features[]'],
  moveIn: filters['move-in'],
  moveOut: filters['move-out'],
  rentalType: filters['rentalType[]'],
  topology: filters['topology[]'],
  type: filters['type[]'],
  ids: filters['ids[]'],
  areaId: filters['areaId[]'],
  moveOutFrom: filters.moveOutFrom,
  moveOutTo: filters.moveOutTo,
  moveInFrom: filters.moveInFrom,
  moveInTo: filters.moveInTo,
  includeBlockedProperties: filters.includeBlockedProperties
});

export const adaptFiltersToUrl = (filters, sortingAlgorithm) => ({
  ...adaptCommonFilters(filters, sortingAlgorithm),
  flexibleMoveIn: filters.flexibleMoveIn,
  flexibleMoveOut: filters.flexibleMoveOut,
  'bathrooms[]': filters.bathrooms,
  'features[]': filters.features,
  'move-in': filters.moveIn,
  'move-out': filters.moveOut,
  'rentalType[]': filters.rentalType,
  'topology[]': filters.topology,
  'type[]': filters.type,
  'ids[]': filters.ids,
  'areaId[]': filters.areaId,
  moveOutFrom: filters.moveOutFrom,
  moveOutTo: filters.moveOutTo,
  moveInFrom: filters.moveInFrom,
  moveInTo: filters.moveInTo
});

export const adaptSortByToUrl = (filters, sortingAlgorithm) => {
  const sortBy = getSortingName(filters.sortBy, sortingAlgorithm);

  return { ...filters, sortBy };
};

export const groupFilterCategory = category => {
  switch (category) {
    case 'rentalType[]':
      return {
        id: 'availabilities',
        title: trans('search.filters.remove.availabilities'),
        appearanceOrder: 1,
        defaultValues: {
          'rentalType[]': []
        }
      };
    case 'move-in':
    case 'move-out':
    case 'moveInFrom':
    case 'moveInTo':
      return {
        id: 'dates',
        title: trans('search.filters.remove.dates'),
        appearanceOrder: 2,
        defaultValues: {
          'move-in': '',
          'move-out': '',
          moveInFrom: '',
          moveInTo: ''
        }
      };
    case 'budget':
      return {
        id: 'budget',
        title: trans('search.filters.remove.budget'),
        appearanceOrder: 3,
        defaultValues: {
          budget: ''
        }
      };
    case 'type[]':
    case 'topology[]':
      return {
        id: 'propertyType',
        title: trans('search.filters.remove.property-type'),
        appearanceOrder: 4,
        defaultValues: {
          'topology[]': [],
          'type[]': []
        }
      };
    case 'features[]':
      return {
        id: 'features',
        title: trans('search.filters.remove.features'),
        appearanceOrder: 5,
        defaultValues: {
          'features[]': []
        }
      };
    case 'bathrooms[]':
    case 'bed':
    case 'areaId[]':
    case 'noDeposit':
    case 'instantBooking':
    case 'verified':
    case 'isBestChoice':
      return {
        id: 'advanced-filters',
        title: trans('search.filters.remove.advanced-filters'),
        appearanceOrder: 6,
        defaultValues: {
          noDeposit: 0,
          instantBooking: 0,
          verified: 0,
          isBestChoice: 0,
          'bathrooms[]': [],
          bed: '',
          'areaId[]': []
        }
      };
    default:
      return {
        id: 'default',
        title: trans('search.filters.clear.cta'),
        appearanceOrder: 1,
        defaultValues: getDefaultFilters()
      };
  }
};

const isFilterEqualFactory = collection => filters => {
  const { sortBy, seoMode, map, ...restFilters } = filters;
  return Object.entries(restFilters)
    .filter(([key]) => collection[key] !== undefined)
    .every(([key, value]) => isEqual(collection[key], value));
};

export const isDefaultFilter = isFilterEqualFactory(
  DEFAULT_FILTERS_TRANSLATED_NO_SORT
);

export const isDefaultFilterPlusPropertyType = isFilterEqualFactory(
  DEFAULT_FILTERS_TRANSLATED_PLUS_PROPERTY_TYPE
);

export const isDefaultFilterFromUrl = urlFilters => {
  const filters = adaptFiltersFromUrl(urlFilters, urlFilters.sortBy);

  return isDefaultFilter(filters);
};

export const isFilteredByDate = filters =>
  !isEmpty(filters.moveIn) ||
  !isEmpty(filters.moveOut) ||
  !isEmpty(filters.moveInFrom) ||
  !isEmpty(filters.moveInTo) ||
  !isEmpty(filters.moveOutFrom) ||
  !isEmpty(filters.moveOutTo);

export default {
  adaptFiltersFromUrl,
  adaptFiltersToUrl,
  getInitialFilters,
  filtersObjectFromUrl,
  formatBudget,
  getBudgetValue,
  getDefaultFilters,
  getActiveFilters,
  getActiveFiltersCount,
  groupFilterCategory,
  adaptSortByToUrl,
  isDefaultFilterFromUrl,
  isDefaultFilter,
  isDefaultFilterPlusPropertyType,
  isFilteredByDate,
  DEFAULT_FILTERS,
  DEFAULT_FILTERS_TRANSLATED,
  EXCLUDED_FILTERS_FROM_COUNT
};
