import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import classNames from 'classnames';
import { CheckboxWithLabel } from '@spotahome/ui-library';
import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

const CheckboxWithFacet = ({ name, className = '', ...rest }) => {
  const { facetSearch } = useFiltersContext();
  const count = facetSearch?.[camelCase(name)];

  return (
    <CheckboxWithLabel
      className={classNames('checkbox-with-label', className)}
      showCount
      count={count}
      {...rest}
    />
  );
};

CheckboxWithFacet.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default CheckboxWithFacet;
