import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trans } from '@spotahome/soyuz/client';

import './SEOContent.scss';

const SEOContent = ({
  title = '',
  subtitle = '',
  body = '',
  isCentered = false
}) => {
  const seoWrapperClasses = classNames('seo-wrapper', {
    'seo-wrapper--centered': isCentered
  });

  return (
    <article className={seoWrapperClasses}>
      {title ? <h2>{title}</h2> : null}
      {subtitle ? <h3>{subtitle}</h3> : null}

      <div dangerouslySetInnerHTML={{ __html: trans(body) }} />
    </article>
  );
};

SEOContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.string,
  isCentered: PropTypes.bool
};

export default SEOContent;
