import { trans } from '@spotahome/soyuz/client';
import { Tag } from '@spotahome/ui-library';

import './HomecardLabel.scss';

const CHECKED = 'checked';
const OFFER = 'offer';
const PERMANENT_CONTRACT = 'permanentContract';
const BEST_CHOICE = 'bestChoice';

const chipsRenders = {
  [CHECKED]: () => (
    <Tag label={trans('homecard.label.checked').toUpperCase()} isDarkMode />
  ),
  [OFFER]: () => null,
  [PERMANENT_CONTRACT]: () => null,
  [BEST_CHOICE]: () => null
};

const renderChip = labelToRender => chipsRenders[labelToRender]();

const HomeCardLabels = () => {
  return renderChip(CHECKED);
};

export const generateHomeCardLabels =
  ({ checked }) =>
  () =>
    checked ? <HomeCardLabels checked={checked} /> : null;

export default generateHomeCardLabels;
