export const SORT_BY_POI_VALUE = 'POI';

export const SORTING = {
  bestMatch: {
    value: 'bestMatch',
    key: 'search.filter_header.sorting.best_match'
  },
  bestPrice: {
    value: 'bestPrice',
    key: 'search.filter_header.sorting.best_price'
  },
  recentlyListed: {
    value: 'recentlyListed',
    key: 'search.filter_header.sorting.recently_published'
  }
};

export const HIDDEN_SORTING = {
  POI: {
    value: SORT_BY_POI_VALUE,
    key: 'search.filter_header.sort_by_distance'
  }
};

export const DEFAULT_SORT_BY = SORTING.bestMatch.value;

const HIDDEN_SORTING_OPTIONS = Object.keys(HIDDEN_SORTING).map(
  key => HIDDEN_SORTING[key]
);
const HIDDEN_SORTING_VALUES = HIDDEN_SORTING_OPTIONS.map(
  option => option.value
);
const AVAILABLE_SORTING_OPTIONS = Object.keys(SORTING).map(key => SORTING[key]);
const AVAILABLE_SORTING_VALUES = AVAILABLE_SORTING_OPTIONS.map(
  option => option.value
);

export const getSortingOptions = () => AVAILABLE_SORTING_OPTIONS;

export const getHiddenSortingConfig = value =>
  HIDDEN_SORTING_OPTIONS.find(sort => value === sort.value);

export const isDefaultSort = value => {
  const isDefault = value === DEFAULT_SORT_BY;
  const isUnknown =
    !AVAILABLE_SORTING_VALUES.includes(value) &&
    !HIDDEN_SORTING_VALUES.includes(value);
  return isDefault || isUnknown;
};

export const isSortByPOI = value => value === SORT_BY_POI_VALUE;

export const getDefaultSortBy = () => DEFAULT_SORT_BY;

export const getSortingName = (sortByValue, variantName) =>
  isDefaultSort(sortByValue) && variantName ? variantName : sortByValue;

export const getSortingAlgorithm = experimentVariant =>
  experimentVariant === 'norr'
    ? 'DataScience-model5NoRR-exploration'
    : experimentVariant === 'revenue'
    ? 'DataScience-model5Revenue-exploration'
    : 'DataScience-model5-exploration';



export default {
  isDefaultSort,
  getHiddenSortingConfig,
  getSortingOptions,
  getSortingName,
  isSortByPOI,
  getDefaultSortBy,
  SORT_BY_POI_VALUE,
  SORTING,
  HIDDEN_SORTING,
  DEFAULT_SORT_BY
};
