import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DefaultHomecard, isPlusPlan } from '@spotahome/ui-library';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { getListingChips } from '@spotahome/ui-library/src/utils/listing';

import { useInView } from 'react-intersection-observer';

import { FavoriteProvider } from '@spotahome/ui-library/src/tenant/context/FavoriteContext';

import {
  getHomecardImageSet,
  getRatingFromReviews,
  getUrlWithMovingDates,
  getBestOfferDiscount
} from '../../utils/homecards';

import { getRentableUnitPrice } from '../../utils/paymentsAdapter';

import { generateHomeCardLabels } from './generateHomeCardLabels';

const MAX_CHIPS_TO_SHOW = 2;

const getHomecardClasses = (visited, selected, tracking) =>
  classNames(
    'home-card',
    'is-animated',
    {
      'home-card--visited': visited,
      'home-card--selected': selected
    },
    tracking
  );

const Homecard = ({
  data: homecard,
  moveIn = '',
  moveOut = '',
  flexibleMoveIn = false,
  flexibleMoveOut = false,
  onCardMouseEnter = () => {},
  onCardMouseLeave = () => {},
  onDelete = null,
  onClick = () => {},
  overflow = false,
  selected = false,
  tracking = '',
  hasLazyLoad = false,
  renderExtraContent = () => {},
  showAvailableFrom = true,
  newTab = true,
  showPrice = true,
  showAvailableFromYear = false,
  withTransparentBackground = false,
  isShowStatus = false,
  onImageChange = () => {},
  showDescription = false,
  isBooked = false,
  pageType = '',
  section = '',
  showFavorite = true,
  linkTo = null
}) => {
  const price = getRentableUnitPrice(
    homecard.monthlyPrice.type,
    homecard.monthlyPrice.minimumPrice,
    homecard.currencyIsoCode,
    homecard.monthlyPrice.pricesByMonth,
    homecard.monthlyPrice.fixedPrice,
    { moveIn, moveOut },
    {
      flexibleMoveIn,
      flexibleMoveOut
    }
  );

  const hasDeposit = homecard.noSecurityDeposit === false;

  const showDepositProjectionChip = hasDeposit;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  useEffect(() => {
    if (inView && isBooked) {
      SoyuzAnalytics.sendGA4Event('show', {
        section: 'soldout-homecard'
      });
    }
  }, [inView]);

  const isPlus = isPlusPlan(homecard.plan);

  const handleMouseEnter = () => {
    onCardMouseEnter(homecard.id);
  };

  const handleMouseLeave = () => {
    onCardMouseLeave(homecard.id);
  };

  const classes = getHomecardClasses(homecard.visited, selected, tracking);

  const homecardBottomChips = getListingChips(
    homecard.noSecurityDeposit,
    homecard.isInstantBooking,
    homecard.billsIncluded,
    homecard.isBestChoice,
    isPlus,
    homecard.allowsPermanentContract,
    {
      useSecurityDepositAlternativeIcon: homecard.noSecurityDeposit,
      addDepositProjectionChip: showDepositProjectionChip
    }
  ).slice(0, MAX_CHIPS_TO_SHOW);

  const onMoreInfoClickHandler = () => {
    SoyuzAnalytics.sendGA4Event('click_more_info_homecard', {});
  };

  const photos = homecard.photos;
  return (
    <div className={classes} ref={ref}>
      <FavoriteProvider
        listingId={homecard.id}
        cityId={homecard.city}
        propertyType={homecard.type}
        price={homecard.displayPrice}
        isFavorite={homecard.isFavorite}
        favoritesCount={homecard.favoritesCount}
      >
        <DefaultHomecard
          price={price}
          favoritesCount={homecard.favoritesCount}
          onDelete={onDelete}
          availableFrom={homecard.firstAvailableDate}
          image={homecard.mainPhotoUrl}
          imageSet={
            homecard.photoUrls ? getHomecardImageSet(homecard.photoUrls) : ''
          }
          link={linkTo ?? getUrlWithMovingDates(homecard.url, moveIn, moveOut)}
          onClick={onClick}
          onMouseEnter={handleMouseEnter} // TODO: this might be treated in the patternLibrary
          onMouseLeave={handleMouseLeave} // TODO: this might be treated in the patternLibrary
          overflow={overflow}
          rating={getRatingFromReviews(homecard.reviews)}
          renderLabels={generateHomeCardLabels(homecard)}
          selected={selected} // TODO: this might be treated in the patternLibrary
          title={homecard.title}
          type={homecard.type}
          verified={homecard.checked}
          hasLazyLoad={hasLazyLoad}
          isSelect={homecard.isSelect}
          renderExtraContent={renderExtraContent}
          showAvailableFrom={showAvailableFrom}
          showAvailableFromYear={showAvailableFromYear}
          showPrice={showPrice}
          newTab={newTab}
          photos={photos}
          isFullTitleDesign
          lazyCarousel
          chips={homecardBottomChips}
          withTransparentBackground={withTransparentBackground}
          isShowStatus={isShowStatus}
          offerDiscount={getBestOfferDiscount(
            homecard.offers,
            homecard.currencyIsoCode
          )}
          description={
            showDescription
              ? {
                  content: homecard.description,
                  area: homecard.area,
                  numberOfBathrooms: homecard.numberOfBathrooms,
                  features: homecard.features
                }
              : null
          }
          descriptionPosition="bottom"
          onMoreInfoClick={onMoreInfoClickHandler}
          onImageChange={onImageChange}
          isBooked={isBooked}
          showFavorite={showFavorite}
          pageType={pageType}
          section={section}
        />
      </FavoriteProvider>
    </div>
  );
};

Homecard.propTypes = {
  data: PropTypes.shape({
    adId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    checked: PropTypes.bool,
    id: PropTypes.string,
    city: PropTypes.string,
    isFavorite: PropTypes.bool,
    favoritesCount: PropTypes.number,
    mainPhotoUrl: PropTypes.string,
    monthlyPrice: PropTypes.shape({
      type: PropTypes.string,
      minimumPrice: PropTypes.number,
      pricesByMonth: PropTypes.shape({
        [PropTypes.string]: PropTypes.number
      }),
      fixedPrice: PropTypes.number
    }),
    currencyIsoCode: PropTypes.string,
    features: PropTypes.shape({ [PropTypes.string]: PropTypes.bool }),
    noSecurityDeposit: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.shape({ src: PropTypes.string })),
    photoUrls: PropTypes.shape({
      homecardHidpi: PropTypes.string,
      homecard: PropTypes.string
    }),
    reviews: PropTypes.shape({
      ratingAverage: PropTypes.number
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    area: PropTypes.number,
    numberOfBathrooms: PropTypes.number,
    type: PropTypes.string,
    url: PropTypes.string,
    visited: PropTypes.bool,
    allowsPermanentContract: PropTypes.bool,
    firstAvailableDate: PropTypes.string,
    offers: PropTypes.arrayOf(PropTypes.shape({})),
    plan: PropTypes.string,
    isBestChoice: PropTypes.bool,
    isInstantBooking: PropTypes.bool,
    isSelect: PropTypes.bool,
    billsIncluded: PropTypes.string,
    landlordId: PropTypes.string
  }).isRequired,
  moveIn: PropTypes.string,
  moveOut: PropTypes.string,
  flexibleMoveIn: PropTypes.bool,
  flexibleMoveOut: PropTypes.bool,
  onCardMouseEnter: PropTypes.func,
  onCardMouseLeave: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  overflow: PropTypes.bool,
  selected: PropTypes.bool,
  tracking: PropTypes.string,
  hasLazyLoad: PropTypes.bool,
  renderExtraContent: PropTypes.func,
  showAvailableFrom: PropTypes.bool,
  newTab: PropTypes.bool,
  showPrice: PropTypes.bool,
  showAvailableFromYear: PropTypes.bool,
  withTransparentBackground: PropTypes.bool,
  pageType: PropTypes.string,
  section: PropTypes.string,
  isShowStatus: PropTypes.bool,
  onImageChange: PropTypes.func,
  showDescription: PropTypes.bool,
  isBooked: PropTypes.bool,
  showFavorite: PropTypes.bool,
  linkTo: PropTypes.string
};

export default memo(
  Homecard,
  (prev, next) =>
    prev.data.id === next.data.id &&
    prev.selected === next.selected &&
    prev.rentalTypes === next.rentalTypes
);
