const BASE_PHOTO_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://photos.spotahome.com'
    : 'https://sah-staging-photos-resized.s3-eu-west-1.amazonaws.com';

export const FORMAT_320 = 'pt_320_240';
export const FORMAT_640 = 'pt_640_480';
const VERIFIED_PHOTO_PATH = 'fsobscalew_1600_900_verified_ur_15_50';
const NON_VERIFIED_PHOTO_PATH = 'fsobscale_1600_900_nonverified_ur_15_50';
const FLOORPLAN_PHOTO_PATH = 'cropsw_170_579_verified_ur_6_50';
const SQUARE_PHOTO_PATH = 'fspt_640_640';
const LARGE_360_PHOTO_PATH = 'smw_5376';
const SMALL_360_PHOTO_PATH = 'smw_2688';

export const getPhotoUrl = (photoId, photoPath) =>
  `${BASE_PHOTO_URL}/${photoPath}/${photoId}.webp`;

// Homecards

const convertPhotoIdsToPhotosStructure = photoIds =>
  photoIds?.map(id => {
    return {
      src: getPhotoUrl(id, FORMAT_640)
    };
  });

const converPhotoIdToPhotoUrlsStructure = photoId => {
  return {
    homecardHidpi: getPhotoUrl(photoId, FORMAT_640),
    homecard: getPhotoUrl(photoId, FORMAT_320)
  };
};

const getPhotoUrls = photoIds =>
  photoIds?.length ? converPhotoIdToPhotoUrlsStructure(photoIds[0]) : {};

const getPhotos = (photoIds, otherPropertyPhotoIds) => [
  ...convertPhotoIdsToPhotosStructure(photoIds),
  ...(convertPhotoIdsToPhotosStructure(otherPropertyPhotoIds) ?? [])
];

export const HomecardFormatter = {
  getPhotoUrls,
  getPhotos
};

// Listing

export const buildFloorplanPhotoUrl = photoId =>
  getPhotoUrl(photoId, FLOORPLAN_PHOTO_PATH);

export const buildPhotoUrl = (photoId, verifiedPhoto) =>
  getPhotoUrl(
    photoId,
    verifiedPhoto ? VERIFIED_PHOTO_PATH : NON_VERIFIED_PHOTO_PATH
  );

export const build360PhotoUrl = (photoId, size) =>
  getPhotoUrl(
    photoId,
    size === 'large' ? LARGE_360_PHOTO_PATH : SMALL_360_PHOTO_PATH
  );

export const buildSquarePhotoUrl = photoId =>
  getPhotoUrl(photoId, SQUARE_PHOTO_PATH);

export const ListingFormatter = {
  buildFloorplanPhotoUrl,
  buildPhotoUrl,
  build360PhotoUrl,
  buildSquarePhotoUrl,
  getPhotoUrl
};
