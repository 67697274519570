import { formatPrice } from '@spotahome/soyuz-currency';

import { getBestOffer } from '@spotahome/ui-library/src/tenant/components/utils/offers';

import { HOMECARD_IMAGE_SIZES } from '../constants';

export const getHomecardImageSet = photoUrls =>
  Object.keys(photoUrls)
    .filter(size => photoUrls[size] && HOMECARD_IMAGE_SIZES[size])
    .map(size => `${photoUrls[size]} ${HOMECARD_IMAGE_SIZES[size]}`)
    .join(', ');

export const getUrlWithMovingDates = (url, moveIn, moveOut) => {
  let getParams = [];
  getParams = moveIn ? [...getParams, `move-in=${moveIn}`] : getParams;
  getParams = moveOut ? [...getParams, `move-out=${moveOut}`] : getParams;

  return getParams.length ? `${url}?${getParams.join('&')}` : url;
};

export const getUrlWithMapParameters = (url, moveIn, moveOut, mapCenter) => {
  let getParams = [];
  getParams = moveIn ? [...getParams, `move-in=${moveIn}`] : getParams;
  getParams = moveOut ? [...getParams, `move-out=${moveOut}`] : getParams;
  getParams = mapCenter ? [...getParams, `mapCenter=${mapCenter}`] : getParams;

  const urlStartType = url.includes('?') ? '&' : '?';

  return getParams.length
    ? `${url}${urlStartType}map=true&${getParams.join('&')}`
    : `${url}${urlStartType}map=true`;
};

export const getBestOfferDiscount = (offers = [], currencyIsoCode) => {
  const bestOffer = getBestOffer(offers);

  if (!bestOffer) {
    return null;
  }

  let discount = `${bestOffer.discountValue} %`;
  if (bestOffer.discountType === 'fixed') {
    discount = formatPrice(bestOffer.discountValue, currencyIsoCode);
  }

  return discount;
};

export const getRatingFromReviews = (reviews = {}) => reviews.ratingAverage;

const MAIN_BILLS = {
  Electricity: 5000,
  Water: 5001,
  Gas: 5002,
  Wifi: 5003
};

const includeNaFeatures = bill => (bill === 'na' ? true : bill);

export const isAllBillsIncluded = (bills = {}) =>
  Object.keys(MAIN_BILLS).every(billId =>
    includeNaFeatures(bills[MAIN_BILLS[billId]])
  );

export const isSomeBillsIncluded = (bills = {}) =>
  Object.keys(MAIN_BILLS).some(billId => bills[MAIN_BILLS[billId]] === true);

export const getMonthlyPrice = displayPrice => ({
  type: 'fixed',
  minimumPrice: displayPrice,
  fixedPrice: displayPrice
});
