import { ApolloLink } from 'apollo-link';

const headersLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      'x-sah-operation-name': operation.operationName,
      accept: 'application/json, */*' // Keep to avoid reporting aborted request
    }
  });
  return forward(operation);
});

export default headersLink;
